[data-reactroot] {
        height: 100% !important;
}

.slick-slider{
        height: 280px;
}

.preview-card{
        margin-right:10px;
}